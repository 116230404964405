<template>
  <v-container pa-0 class="record-announcement record-video" grid-list-md>
    <v-layout row justify-center>
      <v-flex xs12 justify-center align-content-start class="video-wrapper">
        <v-checkbox
          v-if="isRecordedVideoVisible && step !== STEP.UPLOAD"
          v-model="isMirrored"
          dark
          label="Mirror video"
          class="mt-0"
          hide-details
        />
        <div
          class="record-video__video-holder"
          :style="{ 'padding-bottom': aspectRatio * 100 + '%' }"
        >
          <VideoComponent
            :key="currentVideo.url"
            v-if="isCurrentVideoVisible && currentVideo.url"
            :src="currentVideo.url"
            controls
            class="record-video__video video-expo"
            :class="{ flipped: currentVideo.flipped }"
          />
          <video
            v-show="isPreviewVideoVisible"
            key="preview"
            ref="preview"
            autoplay
            muted
            class="record-video__video"
          />
          <video
            key="recording"
            v-show="isRecordedVideoVisible"
            ref="recording"
            id="recording"
            :src="recordingSrc"
            :class="{ flipped: isFlipped }"
            controls
            class="record-video__video"
          />
          <svg-icon
            v-if="normalizedVideos.length === 0 && step === STEP.INITIAL"
            name="video"
            class="rtb-absolute-center rtb-color-primary"
          />
        </div>
        <v-icon
          class="record-icon"
          v-if="recorderState === RECORDER_STATE.RECORDING"
        >
          fiber_manual_record
        </v-icon>
      </v-flex>
    </v-layout>

    <v-layout
      row
      v-if="
        normalizedVideos.length > 0 &&
        step === STEP.INITIAL &&
        editing === false
      "
    >
      <v-flex shrink class="record-video__share-recorded-url">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon small aria-label="Copy Url">
              <v-icon v-on="on" @click="copyVideoUrl(currentVideo.url)">
                share
              </v-icon>
            </v-btn>
          </template>
          <span>{{ getUrlCopyText }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex d-flex align-center mb-2>
        <v-select
          v-model="currentVideoID"
          :items="selectOptions"
          item-value="id"
          item-text="title"
          dark
          hide-details
        />
      </v-flex>
      <v-flex shrink v-if="hasFeaturedRecording">
        <v-checkbox
          v-model="getFeaturedGameRecordingID"
          dark
          dense
          label="featured"
          hide-details
        />
      </v-flex>
      <v-flex shrink>
        <v-btn fab dark small @click="onEditTitle" aria-label="Edit title">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          @click="onPublish"
          aria-label="Publish Again"
          :disabled="publishing"
          :loading="publishing"
        >
          <v-icon>send</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-form
      v-if="editing && step === STEP.INITIAL"
      ref="editingForm"
      @submit.prevent="onEditFormSubmit"
    >
      <v-layout column mt-2>
        <v-layout flex-nowrap class="align-center">
          <v-text-field
            v-model="editTitle"
            label="Title"
            dark
            hide-details
            single-line
            dense
            class="mr-3"
            :rules="titleRules"
          />
          <datepicker
            v-if="!isGame"
            :placeholder="'Announcement Date'"
            v-model="playingDate"
            class="date-picker drop-direction-up mr-3"
            calendar-class="date-picker-calendar"
            input-class="date-picker-input"
          />
          <vue-timepicker
            v-if="!isGame"
            format="HH:mm"
            drop-direction="up"
            v-model="playingTime"
            input-width="5em"
          />
        </v-layout>
        <v-flex mt-3>
          <v-layout justify-center>
            <v-flex xs3>
              <v-btn type="submit" class="popup-button">
                <span class="contrast-text-color">Submit</span>
              </v-btn>
            </v-flex>
            <v-flex xs3>
              <v-btn dark class="popup-button" @click="editing = false">
                <span class="contrast-text-color"> Cancel </span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>

    <template v-if="!editing">
      <!-- Initial -->
      <v-layout
        row
        mt-1
        v-if="step === STEP.INITIAL"
        justify-center
        text-center
      >
        <v-flex>
          <button type="button" @click="onRecordNewVideo" class="popup-button">
            <span class="contrast-text-color"> Record </span>
          </button>
        </v-flex>
        <v-flex>
          <button type="button" class="popup-button" @click="onUploadVideo">
            <span class="contrast-text-color"> Upload </span>
          </button>
        </v-flex>
        <v-flex>
          <button type="button" class="popup-button" @click="onFromURL">
            <span class="contrast-text-color">From URL</span>
          </button>
        </v-flex>
        <v-flex v-if="hasVideos">
          <button type="button" class="popup-button" @click="onDeleteVideo">
            <span class="contrast-text-color"> Delete </span>
          </button>
        </v-flex>
        <v-flex v-if="hasVideos && isHost && isSuper">
          <button type="button" class="popup-button" @click="onDeleteVideos">
            <span class="contrast-text-color"> Delete All </span>
          </button>
        </v-flex>
      </v-layout>

      <!-- Prepare -->

      <v-layout mt-4 v-if="step === STEP.PREPARE" row justify-center>
        <v-flex xs4 d-flex>
          <button type="button" class="popup-button" @click="onStartRecording">
            <span class="contrast-text-color"> Start Recording </span>
          </button>
        </v-flex>
        <v-flex xs4 d-flex>
          <button
            type="button"
            class="popup-button"
            @click="onCancelRecordNewVideo"
          >
            <span class="contrast-text-color"> Cancel </span>
          </button>
        </v-flex>
      </v-layout>

      <!-- Record -->

      <v-layout mt-4 v-if="step === STEP.RECORD" row justify-center>
        <v-flex d-flex xs5>
          <button @click="onStopRecording" class="popup-button">
            <span class="contrast-text-color"> Stop Recording </span>
          </button>
        </v-flex>
      </v-layout>

      <!-- Submit -->

      <!-- TODO(Andrew): remove inline style -->
      <v-form
        ref="form"
        @submit.prevent="postAnnouncement"
        style="width: 100%"
        v-if="step === STEP.SUBMIT"
      >
        <v-layout mt-2 column>
          <v-flex>
            <v-layout flex-nowrap class="align-baseline">
              <v-text-field
                v-model="titleComputed"
                label="Title"
                dark
                :class="{ 'mr-3': !isGame }"
                :rules="titleRules"
              />
              <datepicker
                v-if="!isGame"
                :placeholder="'Announcement Date'"
                v-model="playingDate"
                class="date-picker drop-direction-up mr-3"
                calendar-class="date-picker-calendar"
                input-class="date-picker-input"
              />
              <vue-timepicker
                v-if="!isGame"
                format="HH:mm"
                drop-direction="up"
                v-model="playingTime"
                input-width="5em"
              />
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout row justify-center>
              <v-flex xs4 d-flex>
                <button class="popup-button">
                  <span class="contrast-text-color"> SUBMIT </span>
                </button>
              </v-flex>
              <v-flex xs4 d-flex>
                <button
                  v-if="recordedBlob || videoToUpload"
                  type="button"
                  @click="onDownloadRecording"
                  class="popup-button"
                >
                  <span class="contrast-text-color"> DOWNLOAD </span>
                </button>
              </v-flex>
              <v-flex xs4 d-flex>
                <button
                  type="button"
                  @click="onResetRecording"
                  class="popup-button"
                >
                  <span class="contrast-text-color"> CANCEL </span>
                </button>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>

      <!-- Upload -->

      <v-layout mt-4 v-if="step === STEP.UPLOAD" row justify-center>
        <v-flex xs5 d-flex>
          <button class="popup-button" @click="cancelUpload">
            <v-layout class="contrast-text-color">
              <v-flex d-flex align-center>
                <v-progress-circular
                  indeterminate
                  size="18"
                  color="white"
                  class="spinner"
                />
              </v-flex>
              <v-flex d-flex align-center>Cancel Upload</v-flex>
            </v-layout>
          </button>
        </v-flex>
      </v-layout>
    </template>

    <input
      style="display: none !important"
      type="file"
      accept="video/*"
      ref="uploadInput"
      @change="onStartUploadVideo($event.target.files)"
    />
  </v-container>
</template>

<script>
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"
import { VForm } from "vuetify/lib"
import uniqid from "uniqid"
import moment from "moment"
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue"
import Datepicker from "vuejs-datepicker"
import { uploadMedia } from "@/services/storage.service"
import File from "@/services/file.service"
import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"

import {
  TARGET_GAME,
  TARGET_CLIENT,
  MAX_RECORDING_TIME,
  ACTION_TYPES
} from "@/store/RecordingModule"
import { ActionTypes as TwilioModuleActionTypes } from "../../store/TwilioModule"

import { FormMixin } from "../../mixins/Form"

import { rules } from "../../constants/rules"

/**
 * @readonly
 * @enum {string}
 */
const STEP = {
  INITIAL: "INITIAL",
  PREPARE: "PREPARE",
  RECORD: "RECORD",
  SUBMIT: "SUBMIT",
  UPLOAD: "UPLOAD"
}

/**
 * @readonly
 * @enum {string}
 */
const RECORDER_STATE = {
  RECORDING: "RECORDING",
  STOPPED: "STOPPED"
}

import { Firebase, copyToClipboard } from "@/helpers"

/**
 * @param { { title?: string } } recording
 */
function titleNormalizer(recording) {
  return recording.title === undefined
    ? { ...recording, title: "Untitled" }
    : recording
}

function selectTitleNormalizer(recording) {
  let title = recording.title === undefined ? "Untitled" : recording.title
  if (recording.playingTimestamp) {
    title =
      recording.title +
      " " +
      moment(recording.playingTimestamp).format("MM/DD/YYYY HH:mm:ss")
  }
  return {
    ...recording,
    title
  }
}

/**
 * @param {string} url
 * @returns {string}
 */
function extractFileNameFromURL(url) {
  const buffer = url.split("/")
  return buffer[buffer.length - 1]
}

export default Vue.extend({
  name: "RecordVideo",
  components: { VForm, VueTimepicker, Datepicker, VideoComponent },
  mixins: [FormMixin],
  props: {
    clientID: {
      type: String,
      required: true
    },
    gameID: {
      type: String,
      required: false
    },
    videoTarget: {
      type: String,
      required: true
    },
    aspectRatio: {
      type: Number,
      default: 9 / 16
    },
    hasFeaturedRecording: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // TODO: why 2 sources of thuth?
    publishing: false,
    isFlipped: false,
    recorderState: RECORDER_STATE.STOPPED,
    recorder: null,
    data: [],
    recordedBlob: null,
    uploadingFile: false,
    downloadUrl: null,
    localVideoTrack: null,
    localAudioTrack: null,
    uploadingVideo: false,
    fileUpload: null,
    videoToUpload: null,
    step: STEP.INITIAL,
    lastVideoKey: null,
    recordingSrc: null,
    mediaStream: null,
    storedVideos: null,
    currentVideoID: null,
    editTitle: "",
    editing: false,
    title: "",
    playingTime: "",
    playingDate: "",
    STEP,
    RECORDER_STATE,
    titleRules: rules.announcementVideoTitle,
    urlCopyText: null
  }),
  watch: {
    currentVideoID() {
      if (this.currentVideo) {
        this.resetTimepickerDate(this.currentVideo.playingTimestamp)
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "token", "isHost"]),
    ...mapGetters(["game"]),
    isMirrored: {
      get() {
        return !this.isFlipped
      },
      set(value) {
        this.isFlipped = !value
      }
    },
    titleComputed: {
      get() {
        var d = new Date()
        var theDate =
          ("0" + d.getDate()).slice(-2) +
          "-" +
          ("0" + (d.getMonth() + 1)).slice(-2) +
          "-" +
          d.getFullYear() +
          " " +
          ("0" + d.getHours()).slice(-2) +
          ":" +
          ("0" + d.getMinutes()).slice(-2)
        return (
          this.title ||
          `${this.user.firstname} ${this.user.lastname}${
            this.isGame ? " – " + theDate : ""
          }`
        )
      },
      set(value) {
        this.title = value
      }
    },
    playingTimestampComputed() {
      const timestamp = this.playingDate ? this.playingDate : new Date()

      if (this.playingDate) {
        const [hours, minutes] = this.playingTime.split(":")
        timestamp.setHours(hours)
        timestamp.setMinutes(minutes)
        timestamp.setSeconds(0)
      }

      return timestamp.valueOf()
    },
    isSuper() {
      return this.user.super
    },
    normalizedVideos() {
      return this.storedVideos === null
        ? []
        : Firebase.normalizeSnapshotToArray(this.storedVideos).map(
            titleNormalizer
          )
    },
    selectOptions() {
      return this.storedVideos === null
        ? []
        : Firebase.normalizeSnapshotToArray(this.storedVideos)
            .sort((record_a, record_b) => {
              return record_b.playingTimestamp - record_a.playingTimestamp
            })
            .map(selectTitleNormalizer)
    },
    hasVideos() {
      return this.normalizedVideos.length > 0
    },
    currentVideo() {
      const video = this.normalizedVideos.find(
        video => video.id === this.currentVideoID
      )
      if (video !== undefined) return video
      return null
    },
    isCurrentVideoVisible() {
      return this.currentVideo !== null && this.step === STEP.INITIAL
    },
    isRecording() {
      return this.recorderState === RECORDER_STATE.RECORDING
    },
    isPreviewVideoVisible() {
      return this.step === STEP.PREPARE || this.step === STEP.RECORD
    },
    isRecordedVideoVisible() {
      return this.step === STEP.SUBMIT || this.step === STEP.UPLOAD
    },
    isGame() {
      return this.videoTarget === TARGET_GAME
    },
    getFeaturedGameRecordingID: {
      get() {
        return this.game?.featuredRecording?.recordingID === this.currentVideoID
      },
      async set() {
        console.log("SETTING!!!")
        await this.setFeaturedRecording({
          clientID: this.clientID,
          gameID: this.gameID,
          recordingID: this.currentVideoID
        })
      }
    },
    getUrlCopyText() {
      return this.urlCopyText ?? this.currentVideo?.url ?? "No url"
    }
  },
  async created() {
    if (!this.localVideoTrack && !this.localAudioTrack) {
      this.localVideoTrack = await this[
        TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK
      ]()
      this.localAudioTrack = await this[
        TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK
      ]()
    }
    this.mediaStream = new MediaStream([
      this.localVideoTrack.mediaStreamTrack,
      this.localAudioTrack.mediaStreamTrack
    ])
    this.$refs.preview.srcObject = this.mediaStream
    await this.getVideos()
  },
  beforeDestroy() {
    try {
      this.$refs?.recording.$el.pause()
      this.localVideoTrack.stop()
      this.localAudioTrack.stop()
    } catch (e) {
      console.warn(e.message)
    }
  },
  methods: {
    ...mapActions("twilio", [
      TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK,
      TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK
    ]),
    ...mapActions("recording", [
      "addAnnouncementVideo",
      "deleteAnnouncementVideo",
      "getAnnouncementVideos",
      "setFeaturedRecording"
    ]),
    async onPublish() {
      this.publishing = true
      await this.$store.dispatch(
        `recording/${ACTION_TYPES.RE_PUBLISH_RECORDING}`,
        {
          clientID: this.clientID,
          recordingID: this.currentVideoID
        }
      )
      this.publishing = false
    },
    async getVideos() {
      this.storedVideos = await this.getAnnouncementVideos({
        clientID: this.clientID,
        targetID: this.getTargetID()
      })

      if (this.storedVideos !== null) {
        if (this.normalizedVideos.length > 0) {
          this.currentVideoID =
            this.normalizedVideos[this.normalizedVideos.length - 1].id
        }
      }
    },
    async onStopRecording() {
      this.recorderState = RECORDER_STATE.STOPPED
      this.isFlipped = false
      this.recorder.state == "recording" && this.recorder.stop()
    },
    onStartRecording() {
      this.step = STEP.RECORD
      this.recorderState = RECORDER_STATE.RECORDING
      this.resetTimepickerDate()
      this.startRecording(this.mediaStream, MAX_RECORDING_TIME)
        .then(async recordedChunks => {
          this.recordedBlob = new Blob(recordedChunks, { type: "video/webm" })
          this.recordingSrc = URL.createObjectURL(this.recordedBlob)
          URL.revokeObjectURL(this.recordedBlob)

          this.recorderState = RECORDER_STATE.STOPPED
          this.step = STEP.SUBMIT
          this.resetTimepickerDate()
        })
        .catch(err => console.log(err.message))
    },
    onUploadVideo() {
      this.resetTimepickerDate()
      this.$refs.uploadInput.click()
    },
    resetTimepickerDate(currentDate) {
      let date = currentDate ? new Date(currentDate) : new Date()
      this.playingDate = date
      const hours = ("0" + date.getHours()).slice(-2)
      const minutes = ("0" + date.getMinutes()).slice(-2)
      this.playingTime = `${hours}:${minutes}`
    },
    onStartUploadVideo(fileList) {
      if (fileList.length) {
        this.isFlipped = true
        let file = fileList.item(0)
        // Allow upload if file less then 500 MB (The Cloud Function limit)
        if (file.size / 1024 / 1024 > 500) {
          alert("Max allowed file size is 500MB")
          return
        }
        this.videoToUpload = file
        this.step = STEP.SUBMIT
        this.recordingSrc = URL.createObjectURL(file)
      } else {
        console.log("Have no selected video")
      }
    },
    wait(delayInMS) {
      return new Promise(resolve => setTimeout(resolve, delayInMS))
    },
    startRecording(stream, lengthInMS) {
      this.recorder = new MediaRecorder(stream)

      this.recorder.ondataavailable = event => this.data.push(event.data)
      this.recorder.start()

      let stopped = new Promise((resolve, reject) => {
        this.recorder.onstop = resolve
        this.data = []
        this.recorder.onerror = event => reject(event.name)
      })

      let recorded = this.wait(lengthInMS).then(() => {
        this.recorder.state === "recording" && this.recorder.stop()
      })

      return Promise.race([stopped, recorded]).then(() => this.data)
    },
    async uploadBlob(blob, name) {
      this.uploadingFile = true
      this.downloadUrl = null

      try {
        this.fileUpload = uploadMedia({
          fileName: `recorded-files/${name}`,
          blob,
          token: this.token
        })

        const url = await this.fileUpload
        this.downloadUrl = url
        return url
      } catch (e) {
        throw new Error(e.message)
      } finally {
        this.uploadingFile = false
      }
    },
    onRecordNewVideo() {
      this.step = STEP.PREPARE
    },
    onResetRecording() {
      this.recorder &&
        this.recorder.state === "recording" &&
        this.recorder.stop()
      this.recorderState = RECORDER_STATE.STOPPED
      this.recordingSrc = null
      this.data = []
      this.recordedBlob = null
      this.step = STEP.INITIAL
    },
    onCancelRecordNewVideo() {
      this.step = STEP.INITIAL
    },
    async postAnnouncement() {
      const valid = this.validateForm()
      if (valid) {
        if (this.videoToUpload) {
          this.uploadVideo(this.videoToUpload)
        } else if (this.recordedBlob) {
          this.uploadVideo(this.recordedBlob)
        } else if (this.recordingSrc) {
          const { recordingSrc: url } = this
          await this.createaddAnnouncementVideo({
            url,
            flipped: this.isFlipped,
            name: extractFileNameFromURL(url)
          })
          this.title = ""
          this.recordingSrc = null
          this.$emit("onPosted")
        }
      }
    },
    async createaddAnnouncementVideo(options = {}) {
      this.lastVideoKey = await this.addAnnouncementVideo({
        target: this.videoTarget,
        clientID: this.clientID,
        userID: this.user.id,
        targetID: this.getTargetID(),
        title: this.titleComputed,
        playingTimestamp: this.playingTimestampComputed,
        ...options
      })
    },
    async uploadVideo(blob) {
      this.uploadingVideo = true
      this.step = STEP.UPLOAD

      const fileExtension = blob.type.replace(/.*\//gi, ".")
      const fileName = `${uniqid()}-recorded-file-${this.user.id}${
        fileExtension || ".webm"
      }`

      try {
        const url = await this.uploadBlob(blob, fileName)
        this.uploadingVideo = false

        await this.createaddAnnouncementVideo({
          name: fileName,
          url,
          flipped: this.isFlipped
        })

        this.data = []
        this.title = ""
        this.$emit("onPosted")
      } catch (e) {
        console.error(e)
        this.step = STEP.INITIAL
      } finally {
        this.videoToUpload = null
      }
    },
    cancelUpload() {
      if (this.fileUpload.state === "running") {
        this.fileUpload.cancel()
      } else if (this.fileUpload.state === "success") {
        this.deleteAnnouncementVideo({
          recordingID: this.lastVideoKey,
          clientID: this.clientID
        })
      }

      this.uploadingVideo = false
      this.step = STEP.INITIAL
    },
    async onDeleteVideo() {
      if (confirm("Are you sure you want to delete this video")) {
        const { clientID } = this

        await this.$store.dispatch("recording/deleteAnnouncementVideo", {
          clientID,
          recordingID: this.currentVideoID
        })
        await this.getVideos()
      }
    },
    async onDeleteVideos() {
      if (confirm("Are you sure you want to delete all videos")) {
        const { clientID } = this
        await this.$store.dispatch("recording/deleteAnnouncementVideos", {
          clientID,
          targetID: this.getTargetID()
        })
        await this.getVideos()
      }
    },
    getTargetID() {
      if (this.videoTarget === TARGET_CLIENT) {
        return this.clientID
      } else if (this.videoTarget === TARGET_GAME) {
        return this.gameID
      }
    },
    onEditTitle() {
      this.editing = true
      this.editTitle = this.currentVideo.title || "Video"
    },
    async onEditFormSubmit() {
      const valid = this.$refs.editingForm.validate()

      if (valid) {
        await this.$store.dispatch(
          `recording/${ACTION_TYPES.UPDATE_RECORDING_TITLE}`,
          {
            clientID: this.clientID,
            recordingID: this.currentVideoID,
            title: this.editTitle,
            playingTimestamp: this.playingTimestampComputed
          }
        )
        this.currentVideo.title = this.editTitle
        this.editing = false

        this.getVideos()
      }
    },
    copyVideoUrl(text) {
      copyToClipboard(text)
      this.urlCopyText = "Video url copied!"
      setTimeout(() => {
        this.urlCopyText = null
      }, 1500)
    },
    async onDownloadRecording() {
      const blob = this.recordedBlob || this.videoToUpload
      const extension = blob.type.replace(/.*\//gi, "")
      File.download(blob, `${this.titleComputed}.${extension || "webm"}`)
    },
    onFromURL() {
      const url = window.prompt("Enter URL", "")

      if (url) {
        this.isFlipped = true
        this.step = STEP.SUBMIT
        this.recordingSrc = url
      }
    }
  }
})
</script>

<style lang="scss">
.date-picker {
  width: 7em;

  .date-picker-calendar {
    color: black;
    bottom: 100%;
    right: -5em;
    top: auto;
  }

  .date-picker-input {
    width: 100%;
    height: 2.2em;
    outline: 0;
    border: 1px solid white;
    text-align: center;
  }
}
</style>
<style lang="scss" scoped>
.record-announcement {
  .video-wrapper {
    width: 100%;
    position: relative;
    .record-icon {
      position: absolute;
      right: 20px;
      top: 20px;
      border-radius: 50%;
      color: $color-orange;
      animation: pulse 2s infinite;
    }
  }
  .md4 {
    display: flex;
    flex-wrap: wrap;
  }
  .xs2:first-child .button {
    margin-left: 8px;
  }

  .popup-button {
    background-color: currentColor !important;
    color: $primary_accent_color;
    border-radius: 7px;
    padding: 3px 10px 5px 10px;
  }

  // .spinner {
  //   margin-right: 5px;
  // }
}

@media (max-width: 767px) {
  .record-announcement {
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;

    video {
      /*background: linear-gradient(to top, #010101 0%, #333 50%, #333);*/
      background: transparent;
      margin-bottom: 10px;
      max-width: 50vw;
      max-height: 80vh;
    }
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-orange, 0.75);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.record-video {
  &__video-holder {
    position: relative;
  }

  &__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__share-recorded-url {
    align-self: center;
    & .v-icon {
      color: $primary_accent_color !important;
      caret-color: $primary_accent_color !important;
    }
  }
}
</style>
