export class RuleFactory {
  static required(name = "Field") {
    return function (value) {
      return (
        (value !== undefined && value !== null && value !== "") ||
        `${name} is required`
      )
    }
  }
}
