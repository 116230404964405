import { render, staticRenderFns } from "./RecordVideo.vue?vue&type=template&id=076e696c&scoped=true&"
import script from "./RecordVideo.vue?vue&type=script&lang=js&"
export * from "./RecordVideo.vue?vue&type=script&lang=js&"
import style0 from "./RecordVideo.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./RecordVideo.vue?vue&type=style&index=1&id=076e696c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "076e696c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VContainer,VFlex,VForm,VIcon,VLayout,VProgressCircular,VSelect,VTextField,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/Users/dkurbatov/Documents/TGG/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('076e696c')) {
      api.createRecord('076e696c', component.options)
    } else {
      api.reload('076e696c', component.options)
    }
    module.hot.accept("./RecordVideo.vue?vue&type=template&id=076e696c&scoped=true&", function () {
      api.rerender('076e696c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Recording/RecordVideo.vue"
export default component.exports