var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "record-announcement record-video",
      attrs: { "pa-0": "", "grid-list-md": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "video-wrapper",
              attrs: {
                xs12: "",
                "justify-center": "",
                "align-content-start": "",
              },
            },
            [
              _vm.isRecordedVideoVisible && _vm.step !== _vm.STEP.UPLOAD
                ? _c("v-checkbox", {
                    staticClass: "mt-0",
                    attrs: {
                      dark: "",
                      label: "Mirror video",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.isMirrored,
                      callback: function ($$v) {
                        _vm.isMirrored = $$v
                      },
                      expression: "isMirrored",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "record-video__video-holder",
                  style: { "padding-bottom": _vm.aspectRatio * 100 + "%" },
                },
                [
                  _vm.isCurrentVideoVisible && _vm.currentVideo.url
                    ? _c("VideoComponent", {
                        key: _vm.currentVideo.url,
                        staticClass: "record-video__video video-expo",
                        class: { flipped: _vm.currentVideo.flipped },
                        attrs: { src: _vm.currentVideo.url, controls: "" },
                      })
                    : _vm._e(),
                  _c("video", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPreviewVideoVisible,
                        expression: "isPreviewVideoVisible",
                      },
                    ],
                    key: "preview",
                    ref: "preview",
                    staticClass: "record-video__video",
                    attrs: { autoplay: "", muted: "" },
                    domProps: { muted: true },
                  }),
                  _c("video", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isRecordedVideoVisible,
                        expression: "isRecordedVideoVisible",
                      },
                    ],
                    key: "recording",
                    ref: "recording",
                    staticClass: "record-video__video",
                    class: { flipped: _vm.isFlipped },
                    attrs: {
                      id: "recording",
                      src: _vm.recordingSrc,
                      controls: "",
                    },
                  }),
                  _vm.normalizedVideos.length === 0 &&
                  _vm.step === _vm.STEP.INITIAL
                    ? _c("svg-icon", {
                        staticClass: "rtb-absolute-center rtb-color-primary",
                        attrs: { name: "video" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.recorderState === _vm.RECORDER_STATE.RECORDING
                ? _c("v-icon", { staticClass: "record-icon" }, [
                    _vm._v(" fiber_manual_record "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.normalizedVideos.length > 0 &&
      _vm.step === _vm.STEP.INITIAL &&
      _vm.editing === false
        ? _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "record-video__share-recorded-url",
                  attrs: { shrink: "" },
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      small: "",
                                      "aria-label": "Copy Url",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyVideoUrl(
                                                _vm.currentVideo.url
                                              )
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [_vm._v(" share ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2216527969
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.getUrlCopyText))])]
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { "d-flex": "", "align-center": "", "mb-2": "" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.selectOptions,
                      "item-value": "id",
                      "item-text": "title",
                      dark: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.currentVideoID,
                      callback: function ($$v) {
                        _vm.currentVideoID = $$v
                      },
                      expression: "currentVideoID",
                    },
                  }),
                ],
                1
              ),
              _vm.hasFeaturedRecording
                ? _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          dark: "",
                          dense: "",
                          label: "featured",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.getFeaturedGameRecordingID,
                          callback: function ($$v) {
                            _vm.getFeaturedGameRecordingID = $$v
                          },
                          expression: "getFeaturedGameRecordingID",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        dark: "",
                        small: "",
                        "aria-label": "Edit title",
                      },
                      on: { click: _vm.onEditTitle },
                    },
                    [_c("v-icon", [_vm._v("edit")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        dark: "",
                        small: "",
                        "aria-label": "Publish Again",
                        disabled: _vm.publishing,
                        loading: _vm.publishing,
                      },
                      on: { click: _vm.onPublish },
                    },
                    [_c("v-icon", [_vm._v("send")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.editing && _vm.step === _vm.STEP.INITIAL
        ? _c(
            "v-form",
            {
              ref: "editingForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onEditFormSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-layout",
                { attrs: { column: "", "mt-2": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "align-center",
                      attrs: { "flex-nowrap": "" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "mr-3",
                        attrs: {
                          label: "Title",
                          dark: "",
                          "hide-details": "",
                          "single-line": "",
                          dense: "",
                          rules: _vm.titleRules,
                        },
                        model: {
                          value: _vm.editTitle,
                          callback: function ($$v) {
                            _vm.editTitle = $$v
                          },
                          expression: "editTitle",
                        },
                      }),
                      !_vm.isGame
                        ? _c("datepicker", {
                            staticClass: "date-picker drop-direction-up mr-3",
                            attrs: {
                              placeholder: "Announcement Date",
                              "calendar-class": "date-picker-calendar",
                              "input-class": "date-picker-input",
                            },
                            model: {
                              value: _vm.playingDate,
                              callback: function ($$v) {
                                _vm.playingDate = $$v
                              },
                              expression: "playingDate",
                            },
                          })
                        : _vm._e(),
                      !_vm.isGame
                        ? _c("vue-timepicker", {
                            attrs: {
                              format: "HH:mm",
                              "drop-direction": "up",
                              "input-width": "5em",
                            },
                            model: {
                              value: _vm.playingTime,
                              callback: function ($$v) {
                                _vm.playingTime = $$v
                              },
                              expression: "playingTime",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { "mt-3": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs3: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "popup-button",
                                  attrs: { type: "submit" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "contrast-text-color" },
                                    [_vm._v("Submit")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs3: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "popup-button",
                                  attrs: { dark: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.editing = false
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "contrast-text-color" },
                                    [_vm._v(" Cancel ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.editing
        ? [
            _vm.step === _vm.STEP.INITIAL
              ? _c(
                  "v-layout",
                  {
                    attrs: {
                      row: "",
                      "mt-1": "",
                      "justify-center": "",
                      "text-center": "",
                    },
                  },
                  [
                    _c("v-flex", [
                      _c(
                        "button",
                        {
                          staticClass: "popup-button",
                          attrs: { type: "button" },
                          on: { click: _vm.onRecordNewVideo },
                        },
                        [
                          _c("span", { staticClass: "contrast-text-color" }, [
                            _vm._v(" Record "),
                          ]),
                        ]
                      ),
                    ]),
                    _c("v-flex", [
                      _c(
                        "button",
                        {
                          staticClass: "popup-button",
                          attrs: { type: "button" },
                          on: { click: _vm.onUploadVideo },
                        },
                        [
                          _c("span", { staticClass: "contrast-text-color" }, [
                            _vm._v(" Upload "),
                          ]),
                        ]
                      ),
                    ]),
                    _c("v-flex", [
                      _c(
                        "button",
                        {
                          staticClass: "popup-button",
                          attrs: { type: "button" },
                          on: { click: _vm.onFromURL },
                        },
                        [
                          _c("span", { staticClass: "contrast-text-color" }, [
                            _vm._v("From URL"),
                          ]),
                        ]
                      ),
                    ]),
                    _vm.hasVideos
                      ? _c("v-flex", [
                          _c(
                            "button",
                            {
                              staticClass: "popup-button",
                              attrs: { type: "button" },
                              on: { click: _vm.onDeleteVideo },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "contrast-text-color" },
                                [_vm._v(" Delete ")]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.hasVideos && _vm.isHost && _vm.isSuper
                      ? _c("v-flex", [
                          _c(
                            "button",
                            {
                              staticClass: "popup-button",
                              attrs: { type: "button" },
                              on: { click: _vm.onDeleteVideos },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "contrast-text-color" },
                                [_vm._v(" Delete All ")]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.step === _vm.STEP.PREPARE
              ? _c(
                  "v-layout",
                  { attrs: { "mt-4": "", row: "", "justify-center": "" } },
                  [
                    _c("v-flex", { attrs: { xs4: "", "d-flex": "" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "popup-button",
                          attrs: { type: "button" },
                          on: { click: _vm.onStartRecording },
                        },
                        [
                          _c("span", { staticClass: "contrast-text-color" }, [
                            _vm._v(" Start Recording "),
                          ]),
                        ]
                      ),
                    ]),
                    _c("v-flex", { attrs: { xs4: "", "d-flex": "" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "popup-button",
                          attrs: { type: "button" },
                          on: { click: _vm.onCancelRecordNewVideo },
                        },
                        [
                          _c("span", { staticClass: "contrast-text-color" }, [
                            _vm._v(" Cancel "),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.step === _vm.STEP.RECORD
              ? _c(
                  "v-layout",
                  { attrs: { "mt-4": "", row: "", "justify-center": "" } },
                  [
                    _c("v-flex", { attrs: { "d-flex": "", xs5: "" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "popup-button",
                          on: { click: _vm.onStopRecording },
                        },
                        [
                          _c("span", { staticClass: "contrast-text-color" }, [
                            _vm._v(" Stop Recording "),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.step === _vm.STEP.SUBMIT
              ? _c(
                  "v-form",
                  {
                    ref: "form",
                    staticStyle: { width: "100%" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.postAnnouncement.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "v-layout",
                      { attrs: { "mt-2": "", column: "" } },
                      [
                        _c(
                          "v-flex",
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "align-baseline",
                                attrs: { "flex-nowrap": "" },
                              },
                              [
                                _c("v-text-field", {
                                  class: { "mr-3": !_vm.isGame },
                                  attrs: {
                                    label: "Title",
                                    dark: "",
                                    rules: _vm.titleRules,
                                  },
                                  model: {
                                    value: _vm.titleComputed,
                                    callback: function ($$v) {
                                      _vm.titleComputed = $$v
                                    },
                                    expression: "titleComputed",
                                  },
                                }),
                                !_vm.isGame
                                  ? _c("datepicker", {
                                      staticClass:
                                        "date-picker drop-direction-up mr-3",
                                      attrs: {
                                        placeholder: "Announcement Date",
                                        "calendar-class":
                                          "date-picker-calendar",
                                        "input-class": "date-picker-input",
                                      },
                                      model: {
                                        value: _vm.playingDate,
                                        callback: function ($$v) {
                                          _vm.playingDate = $$v
                                        },
                                        expression: "playingDate",
                                      },
                                    })
                                  : _vm._e(),
                                !_vm.isGame
                                  ? _c("vue-timepicker", {
                                      attrs: {
                                        format: "HH:mm",
                                        "drop-direction": "up",
                                        "input-width": "5em",
                                      },
                                      model: {
                                        value: _vm.playingTime,
                                        callback: function ($$v) {
                                          _vm.playingTime = $$v
                                        },
                                        expression: "playingTime",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", "justify-center": "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "", "d-flex": "" } },
                                  [
                                    _c(
                                      "button",
                                      { staticClass: "popup-button" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "contrast-text-color",
                                          },
                                          [_vm._v(" SUBMIT ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "", "d-flex": "" } },
                                  [
                                    _vm.recordedBlob || _vm.videoToUpload
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "popup-button",
                                            attrs: { type: "button" },
                                            on: {
                                              click: _vm.onDownloadRecording,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "contrast-text-color",
                                              },
                                              [_vm._v(" DOWNLOAD ")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "", "d-flex": "" } },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "popup-button",
                                        attrs: { type: "button" },
                                        on: { click: _vm.onResetRecording },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "contrast-text-color",
                                          },
                                          [_vm._v(" CANCEL ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.step === _vm.STEP.UPLOAD
              ? _c(
                  "v-layout",
                  { attrs: { "mt-4": "", row: "", "justify-center": "" } },
                  [
                    _c("v-flex", { attrs: { xs5: "", "d-flex": "" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "popup-button",
                          on: { click: _vm.cancelUpload },
                        },
                        [
                          _c(
                            "v-layout",
                            { staticClass: "contrast-text-color" },
                            [
                              _c(
                                "v-flex",
                                { attrs: { "d-flex": "", "align-center": "" } },
                                [
                                  _c("v-progress-circular", {
                                    staticClass: "spinner",
                                    attrs: {
                                      indeterminate: "",
                                      size: "18",
                                      color: "white",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { "d-flex": "", "align-center": "" } },
                                [_vm._v("Cancel Upload")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _c("input", {
        ref: "uploadInput",
        staticStyle: { display: "none !important" },
        attrs: { type: "file", accept: "video/*" },
        on: {
          change: function ($event) {
            return _vm.onStartUploadVideo($event.target.files)
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }